<template>
  <div v-if="!isMobileAgent()" class="error--text text-h6 text-center">
    {{ $t("messages.function_work_on_mobile_only") }}
  </div>
  <div v-else>
    <v-row dense class="mb-2">
      <v-col
        cols="3"
        v-for="item in tabs"
        :key="item.status"
        class="text-center"
      >
        <span
          :class="filters.status == item.status ? 'primary--text' : ''"
          class="fs-14 text-decoration-underline"
          @click="onTabChange(item.status)"
        >
          {{ item.text }}
          <br />
          <span v-if="item.status < 5">
            ({{ counts[`count_status_${item.status}`] }})
          </span>
        </span>
      </v-col>
    </v-row>

    <div style="height: calc(100vh - 180px); overflow-y: auto">
      <v-simple-table
        class="table-padding-2 mb-5"
        v-for="(item, key) in items"
        :key="`r_${key}_${item.sku}`"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th
                role="columnheader"
                colspan="4"
                class="text-center purple white--text"
              >
                <span class="fs-16">
                  SKU: {{ item.sku }} - Cần tìm: {{ totalByItem(item) }}
                </span>
                <br />
                <span class="fs-14">
                  <span v-if="item.locations && item.locations.length > 0">
                    {{ item.locations.join("; ") }}
                  </span>
                </span>
              </th>
            </tr>
            <tr>
              <th role="columnheader" class="text-center">Sọt</th>
              <th
                role="columnheader"
                class="text-center"
                v-if="filters.status != 4"
              >
                Cần tìm
              </th>
              <th role="columnheader" class="text-center">Pick</th>
              <th role="columnheader" class="text-center">Đóng</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(child, cKey) in item.finds"
              :key="`c_${cKey}_${item.sku}`"
            >
              <td class="font-weight-medium error--text">
                {{ child.basket_code }}
              </td>
              <td class="" v-if="filters.status != 4">
                <span class="fs-30 font-weight-medium">{{
                  child.remaining_quantity
                }}</span>
                <br />
                <span>{{ child.uids }}</span>
              </td>
              <td>{{ child.employee_pickup_name }}</td>
              <td>{{ child.employee_packing_name }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div class="section-sticky-bottom">
      <v-row dense>
        <v-col cols="6">
          <v-btn small block color="warning" @click="showDialogNotFound"
            >Không thấy</v-btn
          >
        </v-col>
        <v-col cols="6">
          <v-btn small block color="primary" @click="showDialogFinded"
            >Hàng vào sọt</v-btn
          >
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialogNotFound" persistent max-width="400px">
      <FindGoodsNotFound
        v-if="dialogNotFound"
        @cancel="closeDialogNotFound"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="dialogFinded" persistent max-width="400px">
      <FindGoodsFinded
        v-if="dialogFinded"
        @cancel="closeDialogFinded"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>
<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
export default {
  name: "Find",
  components: {
    FindGoodsFinded: () => import("@/components/pos_goods/FindGoodsFinded"),
    FindGoodsNotFound: () => import("@/components/pos_goods/FindGoodsNotFound"),
  },
  data: () => ({
    tabs: [
      {
        text: "Chưa tìm",
        status: 1,
        img: require("@/assets/internal_requests/1_Cho_xu_Ly.png"),
      },
      {
        text: "Bổ sung",
        status: 2,
        img: require("@/assets/internal_requests/2_Dang_xu_ly.png"),
      },
      {
        text: "Không thấy",
        status: 3,
        img: require("@/assets/internal_requests/3_Cho_xac_nhan.png"),
      },
      {
        text: "Đã thấy",
        status: 4,
        img: require("@/assets/internal_requests/4_Da_hoan_thanh.png"),
      },
    ],
    tab: 0,
    page: 1,
    counts: {},
    items: [],
    filters: {
      status: 1,
    },
    isLoading: false,
    dialogNotFound: false,
    dialogFinded: false,
    itemDetail: {},
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.isMobileAgent()) {
      return false;
    }
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    onTabChange(tab) {
      this.filters = { ...this.filters, status: tab };
    },
    getList: debounce(function () {
      httpClient
        .post("/find-goods-employee-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.counts = { ...data.counts };
          this.items = [...data.rows];
        });
    }, 1000),
    showDialogNotFound() {
      this.dialogNotFound = true;
    },
    closeDialogNotFound() {
      this.dialogNotFound = false;
    },
    showDialogFinded() {
      this.dialogFinded = true;
    },
    closeDialogFinded() {
      this.dialogFinded = false;
    },
    totalByItem(item) {
      let total = 0;
      item.finds.forEach((child) => {
        total += child.remaining_quantity;
      });
      return total;
    },
  },
};
</script>
